<template>
	<div class="potential contractDetail">
		<div ref="navBar">
			<van-nav-bar v-if="messageTo" title="付款审批" left-arrow @click-left="_onBackTapInfo" fixed z-index="99" placeholder />
			<!-- <rxNavBar :title="title" v-if="messageTo" :returnPage="routerBack" :params="routerParams"></rxNavBar> -->
			<rxNavBar :title="title" v-else></rxNavBar>
		</div>
		<div class="panel withoutMargin">

			<div class="title">
				<p class="van-multi-ellipsis--l2">{{bill.title}}</p>
			</div>
			<van-row class="info">
				<van-col span="12">
					<p>
						合同号：
						<span>{{bill.contractCode}}</span>
					</p>
				</van-col>
        <van-col span="12">
          <p>
            账单包id：
            <span>{{bill.id}}</span>
          </p>
        </van-col>
			</van-row>
			<div class="info">
				<van-col span="12">
					<p>
						数量：<span>{{bill.count}}</span>
					</p>
				</van-col>
				<van-col span="12">
					<p>
						金额：<span>{{bill.totalAmount}}元</span>
					</p>
				</van-col>

			</div>
		</div>
		<div class="tabWrapper">
			<van-tabs v-model="tabActive" line-width="20" line-height="4" :lazy-render="false" title-active-color="rgb(255,81,45) ">
				<van-tab style="margin-left: 10px" title="账单">
					<div class="bill" v-if="billList.length>0">
						<!--<div v-for="(item,i) in billList" :key="i">
							<div class="billPayTime">{{item.predictBillPayTime.substring(0,10)}}</div>
							<div class="bill_panel">
								<van-row class="bill_info" type="flex" align="center">
									<div class="laber ">
										<div class="laberText  ">{{item.billStatus}}</div>
									</div>
									<van-col span="24">
										<p>{{item.roomDetailedAddress}}</p>
									</van-col>
									<van-col span="24">
										<p>费用周期：<span>{{item.cycle}}</span></p>
									</van-col>
									<van-col span="12">
										<p>费用类型：<span>{{item.billFeeType_id}}</span></p>
									</van-col>
									<van-col span="24">
										<p>描述：<span>{{item.billDescription ? '(' + item.billDescription + ')' : ''}}</span></p>
									</van-col>
									<van-col span="24">
										<p class="total_color bill_money">{{item.totalAmount}}元</p>
									</van-col>
								</van-row>
							</div>
						</div>-->
        <div class="bottom-check" v-for="(item,index) in billList" :key="index">
            <div class="bottom-text" v-if="title!='中后台支付'">
                <span class="bottom-text-left">房间：</span>
                <span class="bottom-text-right">{{item.roomDetailedAddress}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">预计支付时间：</span>
                <span class="bottom-text-right text-red">{{item.predictBillPayTime.substring(0,10)}}元</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">价格：</span>
                <span class="bottom-text-right text-red">{{item.totalAmount}}元</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">费用周期：</span>
                <span class="bottom-text-right">{{item.cycle}}</span>
            </div>
            <div class="bottom-text" >
                <span class="bottom-text-left ">账单状态：</span>
                <span class="bottom-text-right text-green">{{item.billStatus}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">费用类型：</span>
                <span class="bottom-text-right">{{item.billFeeType_id}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">描述：</span>
                <span class="bottom-text-right">{{item.billDescription ? '(' + item.billDescription + ')' : ''}}</span>
            </div>
        </div>
					</div>
				</van-tab>
                <van-tab style="margin-left: 10px" title="审批">
					<div class="bill" v-if="appList.length>0">
						<div class="bottom-check" v-for="(item,i) in appList" :key="i">
            <div class="bottom-text" v-if="item.type==0">
                <span class="bottom-text-left">状态：</span>
                <span class="bottom-text-right text-red">{{item.approvalStatus}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">{{item.type==1?'评论人：':'审批人：'}}</span>
                <span class="bottom-text-right">{{item.staffName}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">{{item.type==1?'评论时间：':'审批时间：'}}</span>
                <span class="bottom-text-right text-red">{{item.approvalTime}}</span>
            </div>
            <div class="bottom-text">
                <span class="bottom-text-left">{{item.type==1?'评论内容：':'审批意见：'}}</span>
                <span class="bottom-text-right">{{item.approvalOpinion}}</span>
            </div>
							<!--<div class="bill_panel">
								<van-row class="bill_info app_info" type="flex" align="center">
									<div class="laber ">
										<div class="laberText  ">{{item.approvalStatus}}</div>
									</div>
									<van-col span="24">
										<p>审批人：<span>{{item.staffName}}</span></p>
									</van-col>
									<van-col span="24">
										<p>审批时间：<span>{{item.approvalTime}}</span></p>
									</van-col>
									<van-col span="24">
										<p>审批意见：<span>{{item.approvalOpinion}}</span></p>
									</van-col>
								</van-row>
							</div>-->
						</div>
					</div>
				</van-tab>

        <van-tab style="margin-left: 10px" title="变更记录" >

          <div class="bill" v-if="changeList.length>0">
            <div class="bottom-check" v-for="(item,i) in changeList" :key="i">
              <div class="bottom-text" >
                <span class="bottom-text-left">操作人：</span>
                <span class="bottom-text-right ">{{item.staffName}}</span>
              </div>
              <div class="bottom-text">
                <span class="bottom-text-left">修改时间：</span>
                <span class="bottom-text-right">{{item.addTime}}</span>
              </div>
              <div class="change-text">
                <span class="bottom-text-left">修改前：</span>
                <div class="bottom-text-right " v-for="items in item.originalDescription">{{items}}</div>
              </div>
              <div class="change-text">
                <span class="bottom-text-left">修改后：</span>
                <div class="bottom-text-right" v-for="items in item.newDescription">{{items}}</div>
              </div>
              <div class="bottom-text">
                <span class="bottom-text-left">原因：</span>
                <span class="bottom-text-right">{{item.refuseReasonTitle}}</span>
              </div>
              <div class="bottom-text">
                <span class="bottom-text-left">备注：</span>
                <span class="bottom-text-right">{{item.remarks}}</span>
              </div>
            </div>
          </div>
          <div v-else style="padding: 50px;font-size: 20px;text-align: center;color: #999999">
            暂无变更记录
          </div>
        </van-tab>
      </van-tabs>

		</div>
		<!-- <div>
			
			<div class="bottomNavigationB bottomNavigationBNew" @click="payment">
        审批
      		</div>
		</div> -->
		<van-popup v-model:show="show" style="background-color: #FAFAFA"  position="bottom" :style="{ height: '60%' }">
      <img style="float: left;margin: 20px 5px 0 15px;width: 15px;height: 15px;"
           src="../../assets/images/colon.png">
      <div style="margin-top: 18px;margin-bottom: 20px;font-size: 15px;font-weight: bold">支付审核</div>
	   <div class="normalBlock" >
	       <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
	       <span class="blockTitle">审核结果</span>
	       <div class="rightText">
	           <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
	           <span class="content-divide" > | </span>
	            <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
	       </div>
	   </div>
	   <div class="displayBlock">
        <span v-if="isReject == true && isPass == false" class="maker" :class="approvalOpinion.length > 0 ? 'orangeColor' : ''">• </span>
	       <div><span :class="isReject == true && isPass == false ? 'suggestTitle' : 'suggestTitleOther'">审批意见</span></div>
	       <div><textarea  class="textArea" v-model="approvalOpinion"></textarea></div>
	   </div>
	   <van-button  class="saveButton_Disable" :class="{'saveButton_Enable': isPass || (isReject && approvalOpinion.length > 0 )}"
	                :disabled=" !(isPass || (isReject && approvalOpinion.length > 0 ))" @click="save" >保存
	   </van-button>

	</van-popup>


	</div>
</template>
<script>
	import BScroll from "better-scroll";
	import {
		NavBar,
		Tab,
		Tabs,
		Row,
		Col,
		Divider,
		Tag,
		Image as VanImage,
		Button,
		ImagePreview,
		Popup,
		Toast
	} from "vant";

	import {
       queryBilldetails,approvalMergeBill
	} from "../../getData/getData";
	import {
		checkAndroid, checkIOS,
		responseUtil,getStaffId
	} from "../../libs/rongxunUtil";
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile';
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	/********判断H5页面是否在web容器中 start*********/
	function openInWebview () {/* 返回true或false; */
		var ua = navigator.userAgent.toLowerCase();
		if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
			return false;
		} else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
			return false;
		} else if (ua.match(/WeiBo/i) == "weibo") {
			return false;
		} else {
			if (ua.match(/Android/i) != null) {
				return ua.match(/browser/i) == null;
			} else if (ua.match(/iPhone/i) != null) {
				return ua.match(/safari/i) == null;
			} else {
				return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
			}
		}
	}
	/********判断H5页面是否在web容器中 start*********/
	export default {
		data() {
			return {
                tabActive:0,
				bill:{ },
				approve:{},
				billList:[],
        		changeList:[],
				appList:[],
				title:'',
				isPass:false,
				isReject:false,
				approvalOpinion:'',//审批意见
				show: false,
				messageTo: false,
				routerBack: '',
				routerParams: {}
			}
		},
		created() {
			if(this.$route.query.messageFlag && this.$route.query.messageFlag == 1) {
				this.messageTo = true
				this.routerBack = this.$route.query.linkPage
				this.routerParams = { 
					type: this.$route.query.type,
					messageFlag: 1,
					linkPage: 'routerMenus'
				}
			}
			this.bill.id= this.$route.query.id
			this.bill.title= this.$route.query.title
			this.bill.contractCode = this.$route.query.contractCode
			this.bill.count = this.$route.query.count
			this.bill.changeFlag = this.$route.query.changeFlag
            this.bill.totalAmount=this.$route.query.totalAmount
            this.bill.addTime=this.$route.query.addTime
			this.title=	this.$route.query.title
            this.getDetail()
		},
		methods: {
			_onBackTapInfo() {
				window.webkit.messageHandlers.openWebPage.postMessage({url:this.routerBack +  "?type=" + this.routerParams.type + "&messageFlag=1&linkPage=routerMenus"});
			},
            getDetail(){
				let that=this
				let initData={}
				initData.id=that.$route.query.id
				initData.currentPage= 1
				initData.numberPage=500
				queryBilldetails(initData).then(function (response) {
	                responseUtil.dealResponse(that, response, () => {
                        console.log(response)
						that.billList=response.data.data.billList.data
						that.appList=response.data.data.appList
						that.changeList=response.data.data.changeList
                    for (let i = 0; i < that.changeList.length; i++) {


                      that.changeList[i].originalDescription = that.changeList[i].originalDescription.split('；')
                      that.changeList[i].newDescription = that.changeList[i].newDescription.split('；')
                    }
	                })
	            })
			},
			payment() {
				this.isPass = false;
				this.isReject = false;
				this.approvalOpinion = '';
				this.show = true;
			},
			choosePass(){
				this.isPass = true;
				this.isReject = false;
				this.isPassValue = 0;
			},
			chooseReject(){
				this.isReject = true;
				this.isPass = false;
				this.isPassValue = 1;
			},
			save(){
	        // 审批拒绝接口
	        // 操作给出提示,重新回到列表页面
	        // 审批同意接口
	        let that = this
	        let initData={}
			const saveList = []
			saveList.push(that.bill.id)
	        initData.payBillList = saveList

	        initData.approvalOpinion = that.approvalOpinion
	        initData.approvalStatus = that.isPassValue   // 0 通过  1 拒绝
	        initData.staff_id = getStaffId()
          	initData.modifiable=0  //不可改之前的付款账户和付款备注
            // if(1==1){
            //     return
            // }
            initData.sourceIdentification = 2  //手机批量支付
			console.log(initData,"initData==")
			 that.isPass = false;
                that.isReject = false;
                that.approvalOpinion = '';
                that.show = false;
			return
            approvalMergeBill(initData).then(function (response) {
	            responseUtil.dealResponse(that, response, () => {
                // that.onRefresh();
                that.isPass = false;
                that.isReject = false;
                that.approvalOpinion = '';
                that.show = false;

                responseUtil.alertMsg(that,'保存成功')
	            })
	        })

	},

		},
		components: {
			[NavBar.name]: NavBar,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Row.name]: Row,
			[Col.name]: Col,
			[Tag.name]: Tag,
			[Divider.name]: Divider,
			[VanImage.name]: VanImage,
			[Button.name]: Button,
			[BScroll.name]: BScroll,
			[ImagePreview.name]: ImagePreview,
			[Popup.name]: Popup,
			rxNavBar,
			dialMobile
		}
	};
</script>
<style lang="less" scoped>
    @radius: 8px;
	@redius: 8px;
	@spanColor: rgba(102, 102, 102, 1);

	.tu_hide {
		display: none;
		width: 19px;
		height: 19px;
		float: right;
	}

	.tu_show {
		display: block;
		width: 19px;
		height: 19px;
		float: right;
	}

	.unpay {
		width: 20px;
		height: 20px;
		float: right;
	}

	.potential {
		width: 100%;
		background-color: #f8f8f8;
		font-size: 12px;
		box-sizing: border-box;
		overflow: hidden;

		p {
			margin: 0;
		}

		span {
			&.hasPadding {
				padding: 0 5px;
			}
		}

		.panel {
			/*width: 345px;*/
			width: 92%;
			margin: 15px auto;
			background-color: rgb(255, 255, 255);
			border-radius: @redius;
			position: relative;
			font-size: 15px;
			text-align: left;
			overflow: hidden;
			padding: 15px;
			box-sizing: border-box;

			&.withoutMargin {
				margin: 3px auto;
			}

			p {
				margin: 6px 0;
				font-weight: bolder;

				span {
					font-weight: normal;
					color: rgb(151, 151, 151);
				}
			}

			.state {
				position: absolute;
				top: 0;
				left: 0;
				font-size: 20px;
				font-weight: bold;
				height: 31px;
				line-height: 33px;
				width: 60px;
				display: block;
				background-color: rgb(163, 153, 153);
				border-top-left-radius: 16px;
				border-bottom-right-radius: 16px;
				color: rgb(255, 255, 255);
				text-align: center;
				transform: scale(0.5);
				transform-origin: 0% 0%;
				font-family: sans-serif;
				padding: 0 10px;


				&.stateGreen {
					background-image: linear-gradient(to right, #66CB94, #28D3B0);
				}

				&.stateRed {
					background-image: linear-gradient(to right, #E02020, #FF431B);
				}

				&.stateGray {
					background-image: linear-gradient(to right, #999999, #999999);
				}

				&.stateOrange {
					background-image: linear-gradient(to right, #FFC274, #FF5D3B);
				}
			}

			.title {
				font-weight: bolder;
				color: rgb(0, 0, 0);
				font-size: 15px;
				/*padding: 20px 15px 15px;*/
				border-bottom: 1px solid rgb(250, 250, 250);

				span {
					font-weight: normal;
					color: rgba(153, 153, 153);
				}
			}

			.info {
				/*padding: 15px;*/

				border-bottom: 1px solid rgb(250, 250, 250);

				&:first-of-type {
					padding-top: 0;
				}

				&:last-of-type {
					border-bottom: none;
					padding-bottom: 0;
				}

				.tenant {
					font-size: 18px;
					display: flex;
					align-items: center;
					.male {
						font-size: 12px;
						color: rgba(86, 155, 236, 1);
						margin-left: 10px;
					}

					.female {
						font-size: 12px;
						color: rgba(255, 140, 219, 1);
						margin-left: 10px;
					}
				}

				.DO_link {
					float: right;
					color: rgba(86, 155, 236, 1);
					cursor: pointer;
					font-size: 12px;
				}
			}

			&.refund {
				p {
					line-height: 24px;
					padding: 0;
					margin: 0;
				}
			}
		}

		.span-description {
			font-size: 15px;
			font-weight: normal;
			color: rgba(153, 153, 153);
		}

		.bill {
			margin-top: 15px;

			.bill_title {
				font-size: 12px;
				background-color: rgb(241, 241, 241);
				color: rgb(155, 155, 155);
				padding: 1px 15px;
			}

			.bill_panel {
				background-color: rgb(255, 255, 255);


				.bill_info {
					position: relative;

					/*padding: 15px;*/
					padding: 0 15px;
					border-bottom: 1px solid rgb(250, 250, 250);

					.img_icon {
						position: absolute;
						top: 0;
						right: -6px;
					}

					&:nth-last-child {
						border-bottom: none;
					}

					.bill_money {
						font-size: 15px;

						&.unreceived {
							opacity: .3;
						}
					}

					p {
						font-weight: bolder;
						font-size: 14px;
						padding-top: 11px;

						&:last-of-type {
							padding-bottom: 10px;
						}
					}

					span {
						font-weight: normal;
						color: rgb(151, 151, 151);
					}
				}
				.app_info{
					 margin-top:10px
				}
			}
		}

		.billPayTime{
			color: rgb(151, 151, 151);
			height: 18px;
			line-height: 18px;
		}

		.application_time {
			text-align: right;
			width: 345px;
			font-size: 12px;
			color: rgb(136, 136, 136);
			padding: 10px 0 5px;
		}

		.tabWrapper {
			width: 100%;
			overflow: hidden;
			margin-bottom: 95px;
		}

		.bottom_btn {
			margin-left: 20px;
			/*padding:10px 15px;*/
			/*margin-left: 4.25rem;*/
			margin-left: 5.55rem;

			.btn {
				width: 120px;
				margin-top: 10px;
				border-radius: @redius;
				background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
				background: -webkit-linear-gradient(left,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				/* Firefox 3.6 - 15 */
				background: linear-gradient(to right,
					rgba(255, 194, 116, 1),
					rgba(255, 93, 59, 1));
				color: rgb(255, 255, 255);
				font-size: 16px;
			}
		}

		// 图片墙
		.swiper_show {
			width: 325px;
			position: relative;
			margin: 15px 10px;
			height: 75px;

			.content {
				position: absolute;
				display: flex;
				justify-content: space-around;
				left: 0px;

				.imgWrap {
					margin-right: 10px;
				}
			}

			.num {
				position: absolute;
				left: 4px;
				top: 6px;
				font-size: 10px;
				background-color: rgb(0, 0, 0);
				opacity: 0.5;
				color: rgb(255, 255, 255);
				padding: 2px 6px;
				display: inline-block;
				border-radius: 10px;
				font-weight: bolder;
				line-height: 10px;
			}
		}

		// 时间线
		.approve {
			margin-top: 20px;

			.time_line {
				.item {
					display: flex;
					align-items: stretch;
					justify-content: space-between;

					.left {
						width: 20px;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 15px;
						/*margin-right: 5px;*/

						.line {
							width: 1px;
							background-color: rgb(255, 75, 39);
							position: absolute;

							&.line_top {
								top: 0px;
								height: ceil(50%-8px);

								&.first_top {
									background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Safari 5.1 - 6.0 */
									background: -o-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Opera 11.1 - 12.0 */
									background: -moz-linear-gradient(bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
									/* Firefox 3.6 - 15 */
									background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 93, 59, 1));
								}
							}

							&.line_bottom {
								bottom: 0;
								height: ceil(50%+10px);
							}
						}

						.point {
							position: relative;
							width: 9px;
							height: 9px;
							border-radius: 50%;
							border: 1px solid rgb(255, 75, 39);
							transform: translateY(-50%);
							z-index: 99;
							background-color: rgb(255, 255, 255);

							&::after {
								content: '';
								display: block;
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								background-color: rgb(255, 75, 39);
								width: 5px;
								height: 5px;
								border-radius: 50%;
							}
						}


					}

					.right {
						width: 100%;
						padding-bottom: 20px;

						.approve_panel {
							margin: 0 15px;
							border-radius: @redius;
							background-color: rgb(255, 255, 255);
							padding: 10px;

							p {
								padding-top: 10px;
								font-weight: bolder;

								&:last-of-type {
									padding-bottom: 10px;
								}

								span {
									font-weight: normal;
									color: rgb(82, 82, 82);
								}
							}
						}
					}
				}

			}
		}

		.small_font {
			font-size: 12px !important;
		}

		.floatR {
			float: right !important;
		}

		.total_color {
			color: rgba(255, 93, 59, 1) !important;
		}
	}

	/* 未收图标 */
	.laber {
		width: 0px;
		height: 0px;
		border-top: 0px solid #FE8D68;
		border-right: 44.6px solid #FE8D68;
		border-bottom: 44.6px solid transparent;
		border-left: 30px solid transparent;
		position: absolute;
		top: 0px;
		right: 0px;


	}

	/* 旋转文字 */
	.laberText {
		width: 33px;
		height: 47px;
		transform: rotate(45deg);
		font-size: 12px;
		color: #ffffff;
	}

	.rentPriceA {
		color: rgba(252, 213, 204, 1);
		font-weight: 500;
	}

	/*免租期样式*/
	.freeTime {
		width: 345px;
		margin: 15px 15px 15px 15px;
		height: auto;
		border-radius: 8px;
		background-color: white;
		overflow: hidden;
	}

	.totalTime {
		width: 345px;
		margin: 0 15px 15px 15px;
		height: 45px;
		border-radius: 8px;
		background-color: white;
		display: flex;
		align-items: center;
	}

	.freeOne {
		height: auto;
		width: 100%;
		border-bottom: 1px solid #f8f8f8;
		overflow: hidden;
	}

	.freeOne-title {
		margin: 5px 0 5px 20px;
		color: red;
		font-weight: bold;
		font-size: 15px;
	}

	.freeOne-title span {
		color: black;
		font-weight: normal;
	}

	.freeOne-time {
		margin: 5px 0 5px 20px;
		color: black;
		font-weight: bold;
		font-size: 15px;
	}

	.freeOne-time span {
		color: black;
		font-weight: normal;
	}

	.totalTitle {
		margin-left: 20px;
		font-weight: bold;
		font-size: 15px;
		height: 20px;
		line-height: 22px;
		width: fit-content;
	}

	.totalLine {
		width: 1px;
		height: 15px;
		margin: 0 8px;
		background-color: gray;
	}

	.totalDays {
		width: 120px;
		height: 20px;
		text-align: left;
		font-size: 15px;
		font-weight: bold;
		line-height: 22px;
		color: red;
	}

	.totalDesc {
		color: gray;
		text-align: left;
		font-size: 15px;
		height: 20px;
		line-height: 22px;
		width: fit-content;
		margin-left: 100px;
	}

	/*交割单标题文字*/
	.contractOrLeaseChange {
		display: inline-block;
		color: red;
		font-weight: bold;
		margin: 15px 0 0 15px;
		font-size: 15px;
	}

	//电子合同
	.electric-contract {
		float: left;
		height: 60px;
		width: 70px;
		margin-top: 0.25rem;
		margin-left: 35px;
		font-size: 14px;
	}

	//电子合同图片
	.electric-contract-photo {
		margin-top: 5px;
		margin-left: 16px;
	}

	.bottom-fixed {
		position: fixed;
		border-top: 1px solid rgb(243, 243, 243);
		width: 100%;
		height: 1.86667rem;
		bottom: 0px;
		background-color: white;
	}

	.bz{
		font-size: 14px;
		font-weight: 800;
		margin-bottom: 5px;
	}
        .bottom-check{
			width:90%;
			 margin: 0 20px 15px 20px;
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: rgb(255, 255, 255);
            border-radius: @radius;
            position: relative;
            font-size: 15px;
            text-align: left;
            overflow: hidden;
        }
        .bottom-text{
            height: auto;
            line-height: 24px;
            margin-left: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .change-text{
          height: auto;
          line-height: 24px;
          margin-left: 14px;
        }
        .bottom-text-left{
            font-weight: bold;
        }
        .bottom-text-right{
            font-size: 14px;
            color: #999999;
        }
        .text-red{
            color: #FF7357;
        }
        .text-green{
            color: #4DC7AF;
        }
		.tabWrapper .van-tab__pane{
            margin-left:0 !important;
		}
		.bottomNavigationB {
			position: fixed;
			right: 10px;
			bottom: 20px;
			// top: 10px;
			float: right;
			height: 40px;
			width: 86px;
			font-size: 14px;
			align-items: center;
			border-radius: 7px;
			background: linear-gradient(to right, #FFC274, #FF5D3B);
			color: white;
			/*margin-left: 70%;*/
			line-height: 40px;
			box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
			font-family: PingFangSC-Semibold;
			text-align: center;
		}
		.bottomNavigationBNew {
			// position: static;
			// margin-right: 10px;
			// margin-left: 10px;
		}
		.normalBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  line-height: 1rem;
  height: 50px;
  margin: 5px auto 0px;
}
		.maker {
  color: #999999;
  float: left;
  padding-left: 14px;
  padding-right: 8px;
  font-size: 28px;
}
.orangeColor {
  color: #ff3c00;
}
.blockTitle {
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
}
.rightText {
  margin-left: auto;
  font-size: 15px;
  color: #999999;
}
.passText {
  flex: 1;
  padding-right: 7px;
}
.choosedStyle {
  color: #ff3c00;
  font-weight: bold;
}
.content-divide {
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
}
.rejectText {
  padding-left: 7px;
  padding-right: 33px;
}
.displayBlock {
  position: relative;
  overflow: hidden;
  background-color: white;
  border-radius: 9px;
  width: 345px;
  height: auto;
  margin: 10px auto;
}
.suggestTitle {
  margin-top: 6px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}
.suggestTitleOther {
  margin-top: 6px;
  margin-left: 32px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  display: block;
}
.textArea {
  resize: none;
  padding: 10px;
  margin: 39px auto 20px;
  height: 100px;
  width: 290px;
  display: block;
  font-size: 14px;
  border-radius: 8px;
  border-style: none;
  background-color: #fafafa;
}
.saveButton_Disable {
  /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
  background-color: rgba(184, 184, 184, 0.2);
  width: 310px;
  height: 45px;
  border-radius: 8px;
  margin: 0px 0px 0px 33px;
  font-size: 18px;
  color: white;
  line-height: 45px;
  text-align: center;
}
.saveButton_Enable {
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll
    0% 0%;
}
</style>
